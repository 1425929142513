.badge {
  margin: auto;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .subText {
    text-transform: uppercase;
  }

  &[data-size='default'] {
    background: url(https://cgcdn.s3.amazonaws.com/images/ondemand/polygon.svg)
      no-repeat;
    width: 78px;
    height: 90px;
    justify-content: center;

    .icon {
      font-size: 16px;
    }

    .subText {
      font-size: 8px;
      margin-top: -4px;
    }
  }

  &[data-size='large'] {
    background: url(https://cgcdn.s3.amazonaws.com/images/ondemand/polygon_130.svg)
      no-repeat;
    width: 130px;
    height: 147px;
    margin-top: 36px;

    .icon {
      margin-top: 27px;
      font-size: 24px;
    }

    .checkins {
      font-size: 32px;
      line-height: 32px;
    }

    .subText {
      margin-top: 4px;
    }
  }
}
