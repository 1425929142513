@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.card {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 0;
  height: 481px;
  width: 300px;

  .upperPart {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-radius: inherit;
    background: white
      url(https://cgcdn.s3.amazonaws.com/images/ondemand/confettis_300.svg)
      no-repeat;
    background-size: contain;
    padding: 0 40px;

    .dismiss {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }

    .helperText {
      color: $medium-gray;
    }

    .infoText {
      text-align: center;
    }

    .pointsAndBadge {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      &.oneElementOnly {
        justify-content: center;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .image {
          margin-bottom: 5px;
        }

        .badge {
          width: 52px;
          height: 52px;
        }
      }
    }

    .progressWrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-evenly;

      .progress {
        .referralsProgress,
        .checkInsProgress {
          margin-top: 10px;
        }
      }

      .noNextLevelText {
        text-align: center;
      }
    }
  }

  .bottomPart {
    height: 77px;
    color: white;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $gladiator-light;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;

    .cta {
      text-align: center;
      margin-bottom: 5px;
    }
  }
}
