@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.noWorkoutsFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px 0;

  @include tablet-medium {
    margin: 250px 0;
  }

  .text {
    text-align: center;
  }
}
