@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .onlineTag {
    border-color: $white !important;
    background-color: $gladiator-lighter;
    color: $gladiator-dark !important;

    span {
      color: $gladiator-dark !important;
    }
  }

  .tag:not(:active):disabled {
    border-color: $text-gray;

    span {
      color: $dark-gray;
    }
  }
}
