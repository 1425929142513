@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.content {
  color: $dark-gray;
  margin-top: 20px;
  margin-bottom: 50px;

  @include tablet-medium {
    margin-top: 30px;
  }

  @include tablet-large {
    margin-bottom: 70px;
  }

  @include desktop {
    margin-top: 50px;
    margin-bottom: 100px;
  }

  .filters {
    margin-top: 50px;
    display: flex;
    align-items: center;
    row-gap: 12px;

    .controls {
      position: relative;
      display: flex;
      gap: 12px;
      flex-direction: column;

      @include tablet-small {
        justify-content: flex-end;
        flex-direction: row;
      }

      .activeBtn[data-variation='outline'][data-emphasis='primary'] {
        background-color: $gladiator-lightest;
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba($dark-gray, 0.2);
}
