@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

$growl-width: 370px;

:root {
  --growl-pos-right: 15px;
}

@include tablet-small {
  :root {
    --growl-pos-right: calc((100vw - 540px) / 2);
  }
}

@include tablet-medium {
  :root {
    --growl-pos-right: calc((100vw - 720px) / 2);
  }
}

@include tablet-large {
  :root {
    --growl-pos-right: calc((100vw - 960px) / 2);
  }
}

@include desktop {
  :root {
    --growl-pos-right: calc((100vw - 1140px) / 2);
  }
}

.growl {
  position: fixed;
  top: 60px;
  width: calc(100% - 30px);
  max-width: $growl-width;
  z-index: 10000;
  transition: 0.75s ease-in-out all;
  animation-name: slidein;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;

  @include tablet-small {
    width: $growl-width;
    top: 79px;
  }

  @include tablet-large {
    top: 84px;
  }

  @include desktop {
    top: 94px;
  }

  @keyframes slidein {
    from {
      right: -100%;
      opacity: 0;
    }

    to {
      right: var(--growl-pos-right);
      opacity: 1;
    }
  }
}
