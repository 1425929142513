@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.filtersCard {
  padding: 0;
  width: 300px;
  right: 0;

  @include tablet-small {
    width: 500px;
  }

  @include tablet-medium {
    width: 690px;
  }

  .upperPart {
    padding: 20px;

    @include tablet-small {
      padding: 30px;
    }

    .header {
      display: flex;
      justify-content: space-between;

      @include tablet-small {
        justify-content: flex-end;
      }

      .closeBtn {
        background: transparent;
        border: none;
        cursor: pointer;
        height: 24px;
        padding: 0;
      }
    }
  }

  .hideCompletedWorkouts {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    align-items: center;

    .toggle:focus-within {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }

  .divider {
    margin: 0;
    border: solid 1px $light-gray;
    border-bottom: none;
  }

  .bottomPart {
    display: flex;
    padding: 20px;
    justify-content: space-between;

    @include tablet-small {
      padding: 20px 30px;
    }

    .showWorkoutsBtn {
      width: 100%;

      @include tablet-small {
        width: initial;
      }
    }
  }
}
