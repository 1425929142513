@use 'sass:map';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

$leftMargins: (
  'tablet-small': 20px,
  'tablet-medium': 50px,
  'tablet-large': 50px,
  'desktop': 95px,
);
$rightMargins: (
  'tablet-small': 30px,
  'tablet-medium': 75px,
  'tablet-large': 145px,
  'desktop': 145px,
);

@mixin textElementWidth($marginLeft, $marginRight) {
  width: calc(100% - $marginLeft - $marginRight);
}

.overlay {
  position: absolute;
  z-index: 3;
  width: 230px;
  color: $white;
  transform: translateX(-50%);
  left: 50%;
  top: 10%;

  @include tablet-small {
    top: initial;
    transform: initial;
    left: map.get($leftMargins, 'tablet-small');
    @include textElementWidth(
      map.get($leftMargins, 'tablet-small'),
      map.get($rightMargins, 'tablet-small')
    );
  }

  @include tablet-medium {
    left: map.get($leftMargins, 'tablet-medium');
    @include textElementWidth(
      map.get($leftMargins, 'tablet-medium'),
      map.get($rightMargins, 'tablet-medium')
    );
  }

  @include tablet-large {
    left: map.get($leftMargins, 'tablet-large');
    @include textElementWidth(
      map.get($leftMargins, 'tablet-large'),
      map.get($rightMargins, 'tablet-large')
    );
  }

  @include desktop {
    left: map.get($leftMargins, 'desktop');
    @include textElementWidth(
      map.get($leftMargins, 'desktop'),
      map.get($rightMargins, 'desktop')
    );
  }
}

.confirmationCard {
  position: absolute;
  top: 0;
  right: 50%;
  z-index: 5;
  transform: translateX(50%);

  @include tablet-small {
    transform: initial;
    right: map.get($rightMargins, 'tablet-small');
  }

  @include tablet-medium {
    top: -10px;
    right: map.get($rightMargins, 'tablet-medium');
  }

  @include tablet-large {
    transform: translateY(-50%);
    top: 50%;
    right: map.get($rightMargins, 'desktop');
  }
}
