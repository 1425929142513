@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.live {
  position: relative;
  background: rgba(51, 51, 51, 0.8);
  padding: 3px 7px;
  border-radius: 4px;
  margin: 10px;
  display: flex;
  align-items: center;
  column-gap: 2px;
  z-index: 11;

  .indicator {
    position: relative;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background-color: $gladiator-light;
      animation: pulse 2s infinite;
    }

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: $gladiator;
    }

    @keyframes pulse {
      0% {
        transform: scale(0);
        opacity: 1;
      }

      50% {
        opacity: 0.5;
      }
      100% {
        transform: scale(1);
        opacity: 0;
      }
    }
  }

  .text {
    color: white;
  }
}
