@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.filtersCardContainer {
  position: absolute;
  z-index: 110;
  bottom: 0;
  right: 5%;
  width: 90%;
  transform: translateY(calc(100% + 20px));

  @include tablet-small {
    width: 300px;
    position: 5;
  }

  .filtersCard {
    padding: 0;

    .upperPart {
      padding: 20px;

      @include tablet-small {
        padding: 30px;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .closeBtn {
          background: transparent;
          border: none;
          cursor: pointer;
          height: 24px;
          padding: 0;
        }
        .clearBtn {
          cursor: pointer;
          display: inline-block;
          padding: 0;
        }
      }
    }
    .divider {
      margin: 0;
      border: solid 1px $light-gray;
      border-bottom: none;
    }

    .bottomPart {
      display: flex;
      padding: 20px;
      justify-content: space-between;

      @include tablet-small {
        padding: 20px 30px;
      }

      .showWorkoutsBtn {
        width: 100%;

        @include tablet-small {
          width: initial;
        }
      }
    }

    .startTimeRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 5px;
    }

    .btnWorkouts {
      text-align: center;
    }

    .slider {
      width: 100%;
      max-width: 300px;
    }
  }
}
