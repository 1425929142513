@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.bottomPart {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .workoutTitle {
    color: $dark-gray;

    .textLink {
      color: inherit;
      text-decoration: inherit;
    }

    .textLink:hover {
      color: $text-gray;
    }
  }

  .actions {
    display: flex;
    column-gap: 10px;
  }
}
