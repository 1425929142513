@use '../../WorkoutCard.module';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.upperPart {
  height: 231px;
  background-size: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  background-position: center top;
  background-color: $gladiator;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background: linear-gradient(0deg, rgba($black, 0.5), transparent);
  }

  .placeholderLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .detailsWrapper {
    z-index: WorkoutCard.$overlayZIndex;
    position: absolute;
    bottom: 0;
    width: 100%;

    .details {
      display: flex;
      padding: 10px;
      justify-content: space-between;
      align-items: flex-end;

      .trainerInfo {
        display: flex;

        .nameWrapper {
          margin-left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          row-gap: 2px;
          color: $white;
          text-shadow: 0px 10px 25px rgba($black, 0.05);

          .region {
            display: flex;
            align-items: center;

            .icon {
              font-size: 15px;
              margin-right: 2px;
            }
          }
        }
      }

      .duration {
        color: $white;
        background-color: $dark-gray;
        border-radius: 7px;
        padding: 3px 6.5px;
      }
    }
  }
}
