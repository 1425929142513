@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.trainerInfo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  @include tablet-medium {
    margin-bottom: 0;
  }

  .blockLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    margin-left: -10px;
    padding: 10px 20px 10px 10px;
    border-radius: 10px;

    &:hover {
      background-color: $lighter-gray;
      padding-right: 10px;

      .arrow {
        color: $dark-gray;
        margin-left: 15px;
      }
    }

    .arrow {
      color: $text-gray;
      margin-left: 5px;
    }

    .meta {
      margin-left: 10px;

      .trainerName {
        margin: 0;
        color: $dark-gray;
      }

      .trainerRating {
        display: flex;
        align-items: center;
        margin-top: 5px;

        .ratingIcon {
          color: $gladiator;
          font-size: 14px;
        }

        .rating {
          color: $dark-gray;
          margin: 0 0 0 3px;
        }

        .total {
          color: $medium-gray;
          margin: 0 0 0 3px;
        }
      }
    }
  }
}
