@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.searchField {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;

  .select {
    width: 100%;
  }
}
