@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.wrapper {
  width: 100%;
  border-radius: 10px;

  .placeholder {
    position: relative;
    border-radius: 10px;
    padding-bottom: calc(9 / 16 * 100%);
    background-color: $dark-gray;
    background-image: url(https://cgcdn.s3.amazonaws.com/images/ondemand/video_placeholder_background.jpg);
    backdrop-filter: blur(20px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .content {
      position: absolute;
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 10px;

      .info {
        color: $white;
        text-align: center;
        padding: 0 30px;
      }
    }
  }
}
