@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.workoutPageControls {
  margin: 20px 0;

  @include tablet-medium {
    margin-top: 35px;
  }

  @include tablet-large {
    margin-top: 30px;
  }

  @include desktop {
    margin-top: 58px;
  }

  .back {
    text-decoration: none;
    color: $medium-gray;

    &:hover {
      color: $text-gray;
    }
  }
}
