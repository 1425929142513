@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.workoutCheckIn {
  align-self: center;
  position: relative;

  @include tablet-medium {
    margin-left: auto;
  }

  .checkInTooltip {
    position: absolute;
    z-index: 2;
    width: 200px;
    top: -25%;
    transform: translate(-45%, -90%);
    padding: 15px;
    margin-left: 50%;

    @include tablet-medium {
      margin-left: 0;
    }
  }

  .checkInButton {
    align-self: center;
    width: 200px;

    @include tablet-medium {
      margin-left: auto;
    }
  }

  .checkedInStatus {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 18px;
    padding-right: 18px;
    color: $gladiator;

    @include tablet-medium {
      margin-left: auto;
    }
  }

  .checkedInIcon {
    margin-right: 10px;
    font-size: 18px;
    height: 18px;
  }

  .checkInLimitReached {
    color: $gladiator;
  }

  .limitReachedIcon {
    color: $gladiator;
    font-size: 18px;
    margin-right: 10px;
  }

  .limitReachedContainer {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 18px;
  }

  .errorMsg {
    color: $gladiator;
    margin-top: 10px;
    text-align: center;
  }

  .btnWrapper {
    width: 200px;
  }
}
