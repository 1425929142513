@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.mixins/mixins.module.scss';

$shimmerEffectSize: 2000px;
$shimmerVelocity: 4s;

.skeleton {
  margin: 22px auto 100px;
  display: flex;
  flex-direction: column;

  @include tablet-medium {
    margin-top: 30px;
  }

  @include tablet-large {
    margin: 24px auto 70px;
  }

  @include desktop {
    margin: 50px auto 100px;
  }

  .title {
    height: 30px;
    width: 210px;
    max-width: 100%;
    border-radius: 100px;
    background: $lighter-gray;
    margin-bottom: 15px;
    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

    @include tablet-small {
      height: 45px;
      width: 540px;
    }

    @include tablet-medium {
      height: 48px;
      width: 444px;
    }
  }

  .subtitle {
    height: 14px;
    width: 282px;
    max-width: 100%;
    border-radius: 100px;
    background: $lighter-gray;
    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

    @include tablet-small {
      height: 25px;
      width: 635px;
    }

    @include tablet-medium {
      height: 22px;
      width: 550px;
    }
  }

  .filter {
    height: 40px;
    width: 138px;
    border-radius: 30px;
    margin-top: 30px;
    background: $lighter-gray;
    align-self: flex-end;
    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

    @include tablet-large {
      margin-top: 50px;
    }
  }
  .workoutsGrid {
    margin-top: 30px;
    row-gap: 50px;
    column-gap: 30px;
    justify-content: center;

    @include tablet-large {
      margin-top: 50px;
    }
  }
}

.workoutBox {
  min-height: 319px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

  @include desktop {
    min-height: 340px;
  }
}
