@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

$overlayZIndex: 10;

.card {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;

  .artifacts {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .blockLink {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
}
