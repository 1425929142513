@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.content {
  padding: 50px 0;
  row-gap: 50px;

  @include tablet-small {
    padding-bottom: 100px;
  }
}

.heading {
  margin-bottom: 20px;
}
