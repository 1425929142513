@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.content {
  color: $dark-gray;
  position: relative;

  .videoWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  .userInformationContainer {
    padding: 15px 0 30px;
    border-bottom: 1px solid $light-gray;
    display: flex;
    flex-direction: column;
    position: relative;

    @include tablet-medium {
      padding: 25px 0 13px;
      flex-direction: row;
      justify-content: space-between;
    }

    @include tablet-large {
      padding-bottom: 44px;
      flex-direction: row;
      justify-content: space-between;
    }

    @include desktop {
      padding-bottom: 46px;
      flex-direction: row;
      justify-content: space-between;
    }

    .checkinAlert {
      width: 400px;
      max-width: 100%;
    }

    .checkinBadge {
      position: absolute;
      bottom: 0px;
      right: 50%;
      transform: translateX(50%);

      @include tablet-small {
        bottom: 270px;
        right: 10px;
        transform: translateX(0);
      }

      @include tablet-medium {
        bottom: 200px;
      }

      @include tablet-large {
        bottom: 240px;
      }
    }
  }
}
