@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.alternativeDays {
  margin-top: 20px;
  text-align: center;

  .numberOfWorkouts {
    color: $dark-gray;
    font-weight: bold;
  }

  .dayOfWeek {
    color: $gladiator;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
