@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.heading {
  margin-bottom: 20px;
}

.iconAndText {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .icon {
    font-size: 16px;

    @include tablet-small {
      font-size: 20px;
    }
  }

  .text {
    margin-left: 12px;
  }
}
