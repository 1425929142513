@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.workoutHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  @include tablet-small {
    align-items: center;
    margin-bottom: 33px;
    flex-direction: row;
  }

  @include desktop {
    margin-bottom: 29px;
  }

  .title {
    margin-bottom: 15px;

    @include tablet-small {
      margin-bottom: 0;
    }
  }

  .btn {
    align-self: flex-end;
  }
}
