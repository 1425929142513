@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.mixins/mixins.module.scss';

$shimmerEffectSize: 2000px;
$shimmerVelocity: 4s;

.skeleton {
  .title {
    height: 19px;
    width: 263px;
    max-width: 100%;
    border-radius: 100px;
    margin-bottom: 15px;

    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

    @include tablet-small {
      width: 300px;
    }

    @include tablet-medium {
      height: 24px;
      width: 525px;
      margin-bottom: 25px;
    }
  }

  .subtitle {
    height: 19px;
    width: 199px;
    border-radius: 100px;
    margin-bottom: 15px;
    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);
  }

  .videoBox {
    height: 163px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 15px;

    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

    @include tablet-small {
      height: 286px;
      margin-bottom: 25px;
    }

    @include tablet-medium {
      height: 515px;
    }

    @include tablet-large {
      margin-bottom: 12px;
    }
  }

  .trainerInfoContainer {
    display: flex;
    margin-bottom: 77px;
    flex-direction: column;

    @include tablet-medium {
      justify-content: space-between;
      flex-direction: row;
    }

    .trainerData {
      display: flex;
      align-items: center;

      .trainerImage {
        height: 48px;
        width: 48px;
        border-radius: 100px;
        margin-right: 4px;

        @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

        @include tablet-medium {
          margin-right: 12px;
        }

        @include desktop {
          margin-right: 4px;
        }
      }

      .trainer {
        height: 14px;
        width: 104px;
        border-radius: 100px;
        margin-bottom: 4px;

        @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

        @include tablet-medium {
          margin-bottom: 5px;
        }
      }

      .trainerName {
        height: 18px;
        width: 104px;
        border-radius: 100px;

        @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);
      }
    }
  }

  .automatedCheckInBox {
    height: 101px;
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;

    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

    @include tablet-small {
      align-self: center;
      margin-top: 20px;
    }

    @include tablet-medium {
      height: 85px;
      width: 329px;
      margin-top: 0px;
    }
  }

  .highlightsPlaceholder {
    height: 22px;
    width: 92px;
    border-radius: 100px;
    margin-bottom: 10px;

    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

    @include tablet-small {
      margin-bottom: 22px;
    }
    @include desktop {
      margin-bottom: 17px;
    }
  }

  .overviewPlaceholder {
    height: 22px;
    width: 92px;
    border-radius: 100px;
    margin-bottom: 124px;

    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

    @include tablet-medium {
      margin-bottom: 0px;
    }
  }

  .workoutTimePlaceholder {
    height: 26px;
    width: 123px;
    border-radius: 100px;
    margin-bottom: 12px;

    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

    @include tablet-small {
      margin-bottom: 20px;
    }

    @include desktop {
      margin-bottom: 13px;
    }
  }

  .workoutIntervalPlaceholder {
    height: 26px;
    width: 123px;
    border-radius: 100px;
    margin-bottom: 138px;

    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

    @include tablet-small {
      margin-bottom: 122px;
    }

    @include tablet-medium {
      margin-bottom: 118px;
    }

    @include desktop {
      margin-bottom: 124px;
    }
  }

  .equipmentPlaceholder {
    height: 26px;
    width: 154px;
    border-radius: 100px;
    margin-bottom: 29px;

    @include shimmer-effect($shimmerEffectSize, $shimmerVelocity);

    @include tablet-medium {
      margin-bottom: 100px;
    }
  }
}
