@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.workouts {
  margin-top: 20px;
  row-gap: 20px;

  @include tablet-large {
    margin-top: 50px;
    row-gap: 50px;
  }

  &.filtered {
    margin-top: 0;

    @include tablet-large {
      margin-top: 20px;
    }
  }
}

.heading {
  margin: 30px 0;
}
