@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

$header-default-padding: 20px;
$active-border-width: 3px;

.nav {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  border-bottom: 1px solid $light-gray;

  .list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    column-gap: 20px;
    align-items: center;
    cursor: pointer;

    &.collapsed {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0px;

      .item {
        padding: 8px 0;

        &.active {
          border-bottom: 0 none;
        }
      }
    }

    .item {
      padding: 16px 0;

      &.active {
        border-bottom: 3px solid $black;
        align-self: flex-end;
      }
    }
  }
}

.link {
  color: $medium-gray;
  text-decoration: none;
  display: inline-block;
  border-radius: 100px;
  padding: 10px 20px;
  background-color: transparent;
  transition: 0.15s linear background-color;

  .date {
    text-align: center;
  }
}
.nav::-webkit-scrollbar {
  display: none;
}
.link:hover {
  background-color: $lighter-gray;
}
