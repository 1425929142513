@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.filterGroup {
  padding-top: 20px;
  @include tablet-small {
    padding-top: 25px;
  }

  &:not(:last-child) {
    border-bottom: solid 1px $light-gray;
    padding-bottom: 20px;
    @include tablet-small {
      padding-bottom: 25px;
    }
  }

  .heading {
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .filterBtnsWrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .activeBtn {
      background-color: $gladiator-lightest;
    }
  }
}
