@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.content {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;

  @include tablet-small {
    justify-content: flex-end;
    flex-direction: row;
    gap: initial;
  }

  .favoriteBtn {
    @include tablet-small {
      margin-right: 20px;
    }
  }

  .activeBtn[data-variation='outline'][data-emphasis='primary'] {
    background-color: $gladiator-lightest;
  }

  .clearBtn {
    @include tablet-small {
      margin-left: 10px;
      padding: 0 10px;
    }
  }

  .filters {
    position: absolute;
    z-index: 110;
    bottom: 0px;
    transform: translateY(calc(100% + 20px));
  }
}
