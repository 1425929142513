@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

@mixin set-width-height-ruleset($value) {
  min-width: $value;
  min-height: $value;
  width: $value;
  height: $value;
}

.favoriteButton {
  margin: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
  cursor: pointer;

  &[data-size='xlarge'][data-icon='true'][data-children='false'] {
    font-size: 13.333px;
    padding: 10px;

    @include set-width-height-ruleset(40px);

    @include tablet-medium {
      @include set-width-height-ruleset(44px);
    }

    @include tablet-large {
      @include set-width-height-ruleset(40px);
    }

    @include desktop {
      @include set-width-height-ruleset(44px);
    }

    &:disabled {
      pointer-events: auto;
    }
  }

  &[data-size='large'][data-children='true'] {
    height: 22px;
    position: static;
    text-align: right;
    background: none;
    border: none;

    &:focus,
    &:active {
      border: none;
      color: $black;
    }
  }

  span {
    &[data-size='large'][data-icon='icon-heart'] {
      color: $gladiator-light;
    }
  }

  &.favorited {
    span {
      &[class*='button_content'] {
        color: $gladiator-light;
      }
    }
  }
}

.favoriteLoading {
  opacity: 0.5;
}
