@use '../../WorkoutCard.module';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.playIconOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: WorkoutCard.$overlayZIndex;
  align-items: center;
  justify-content: center;
  display: flex;

  .circle {
    display: none;
  }

  &:hover {
    .circle {
      background: rgba($dark-gray, 0.85);
      border: 2px solid $white;
      border-radius: 50%;
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        color: $white;
      }
    }
  }
}
