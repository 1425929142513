$playerBorderRadius: 10px;

.video-js {
  background-color: transparent;

  &:not(.vjs-fullscreen) {
    border-radius: $playerBorderRadius;

    .vjs-control-bar {
      border-bottom-left-radius: $playerBorderRadius;
      border-bottom-right-radius: $playerBorderRadius;
    }

    .vjs-tech {
      border-radius: $playerBorderRadius;
    }
  }
}
